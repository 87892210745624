import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { ReactElement } from "react";
import FormattedText from "../../../../../../../../Components/FormattedText";

const btnStyle = {
    width: "100%",
    height: "6.25vh",
};

const btnTextStyle = {
    color: "white",
    fontWeight: 900,
};

const SubmitDialog = (props: {
    title: string;
    handleClose: (...args: any[]) => any;
    open: boolean;
    handleLeftButtonClick: (...args: any[]) => any;
    handleRightButtonClick: (...args: any[]) => any;
    children: ReactElement
}): ReactElement =>
    <Dialog
        PaperProps={{
            style: {
                width: "66%",
                height: "26%",
            }
        }}
        open={props.open}
        onClose={props.handleClose}
    >
        <DialogTitle>
            <FormattedText
                text={props.title}
                variant='h1'
            />
        </DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent >
        <DialogActions
            style={{
                marginBottom: "0.6vh"
            }}
        >
            <Button
                style={{
                    ...btnStyle,
                    // eslint-disable-next-line max-len
                    background: "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)",
                    border: "#fff"
                }}
                variant='outlined'
                onClick={props.handleLeftButtonClick}
            >
                <FormattedText style={btnTextStyle} variant='subtitle1' text='Cancel' />
            </Button>
            <div style={{ width: 15 }} />
            <Button
                style={{
                    ...btnStyle,
                }}
                onClick={props.handleRightButtonClick}
            >
                <FormattedText style={btnTextStyle} variant='subtitle1' text='Submit' />
            </Button>
        </DialogActions>
    </Dialog >;

export default SubmitDialog;