import Slider from "@material-ui/core/Slider";
import React, { ReactElement, useState, useRef } from "react";
import { FormControl, FormLabel } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const marks: any[] = [];
for (let i = 1; i <= 10; i++) {
    marks.push({ value: i, label: "" + i });
}

function SliderComponent(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any
}
): ReactElement {
    return (
        <Grid container xs={12}>
            <Grid item
                xs={12}
                style={{
                    textAlign: "left",
                }}>
                <FormControl sx={{ minWidth: "95%" }}>
                    <FormLabel>
                        <Typography variant='subtitle1'>
                            {props.label}
                        </Typography>
                    </FormLabel>
                    <Slider
                        valueLabelDisplay="on"
                        track={false}
                        step={1}
                        marks={marks}
                        min={1}
                        max={10}
                        onChangeCommitted={(_, value) => {
                            props.setBlur(props.blurValue + 1);
                            props.setInputValue(value + "" || "5");
                            (document.activeElement as HTMLElement).blur();
                        }}
                        onChange={(_, value) => {
                            props.setInputValue(value + "" || "5");
                        }}
                        value={parseInt(props.inputValue) || 5}
                    />
                </FormControl>
            </Grid>
        </Grid >
    );
}

export default SliderComponent;