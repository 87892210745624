import React, { ReactElement, useContext } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import Cookies from "js-cookie";
import FormattedText from "../../../../Components/FormattedText";
import { FACILITY_NAME_COOKIE } from "../../../../Resources/constants";
import SubmitDialog from "./Components/SubmitDialog";
import ProgressBar from "./Components/ProgressBar";
import { ReactComponent as NextArrow } from "./Assets/next_arrow.svg";
import { ReactComponent as BackArrow } from "./Assets/back_arrow.svg";
import { PopupAlert } from "../../../../Hooks";
import { useFormikContext } from "formik";
import { updateAnswers } from "../../../../Providers/backend";
import { IFormikContext, FormikCtx } from "../../../../Contexts/formikContext";
import { PageTitle, Question } from "../../../../Types";

const Stepper = ({
    canvas,
    stepsLength,
    pageTitles,
    questions,
    step,
    stepValidation,
    onStepChange,
    visualization,
}: {
    canvas: any;
    stepsLength: number;
    pageTitles: PageTitle[];
    questions: Question[];
    step: number;
    stepValidation: () => boolean;
    onStepChange: (pageNum: React.SetStateAction<number>) => void;
    visualization: any;
}): ReactElement => {
    const customFormikContext = useContext<IFormikContext>(FormikCtx);
    const { errors, values } = useFormikContext();

    const filteredValues = React.useMemo(() => {
        return typeof values === "object" && values
            ? Object.entries(values)
                .filter(([_, value]) => value.page === step - 2)
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
            : {};
    }, [values, step]);

    const handlePageChange = async (pageNum: React.SetStateAction<number>) => {
        if (stepValidation() && Object.keys(errors).length === 0) {
            if (
                (localStorage.getItem("local") === "true" && step >= 3) ||
                (localStorage.getItem("local") === "false" && step >= 1 && step !== 2) &&
                Object.keys(filteredValues).length > 0
            ) {
                try {
                    await updateAnswers(filteredValues);
                    onStepChange(pageNum);
                } catch {
                    PopupAlert("Was not able to update answers.", "error");
                }
            } else {
                onStepChange(pageNum);
            }
        } else {
            new Set(Object.values(errors) as unknown as string).forEach((msg) =>
                PopupAlert(msg, "error")
            );
        }
        window.scrollTo(0, 0);
    };

    const buttonStyles = {
        width: "100%",
        height: "7vh",
        borderRadius: "0",
        color: "#fff",
    };

    const buttonTextStyle = { fontSize: 22, fontWeight: 900 };

    const determineHideSteps = () => {
        let declarationHide = 60, consentHide = 60;

        if (Cookies.get("facilityName")?.includes("Dentaprime City")) {
            if (visualization[0] || visualization[1]) {
                declarationHide = 4;
                consentHide = 12;
            }
        } else if (Cookies.get("facilityName")?.includes("Dentaprime London")) {
            if (visualization[3] && visualization[4]) {
                declarationHide = 14;
                consentHide = 15;
            } else if (visualization[3] || visualization[4]) {
                declarationHide = 14;
                consentHide = 14;
            }
        } else if (Cookies.get("facilityName")?.includes("Dentaprime Clinic")) {
            if (visualization[0] || visualization[1]) {
                declarationHide = localStorage.getItem("local") === "false" ? 2 : 4;
                consentHide = localStorage.getItem("local") === "false" ? 19 : 22;
            }
        }

        if (!visualization[0]) {
            declarationHide = 50;
        }

        if (!visualization[1]) {
            consentHide = 50;
        }

        if (!visualization[2]) {
            consentHide = 50;
        }

        return { declarationHide, consentHide };
    };

    const { declarationHide, consentHide } = determineHideSteps();

    const BackButton = () => (
        <Button
            style={{
                ...buttonStyles,
                background: "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)",
                border: "#fff",
            }}
            disabled={step === 0}
            variant="outlined"
            onClick={() => handlePageChange(step - 1)}
        >
            <BackArrow style={{ marginRight: "3%" }} />
            <FormattedText style={buttonTextStyle} text="Back" />
        </Button>
    );

    const NextButton = () => {
        if (step === stepsLength - 1) {
            return (
                <SubmitDialog
                    canvas={canvas}
                    pageTitles={pageTitles}
                    questions={questions}
                    btnStyle={buttonStyles}
                    btnTextStyle={buttonTextStyle}
                />
            );
        }

        return (
            <Button
                style={buttonStyles}
                disabled={Cookies.getJSON(FACILITY_NAME_COOKIE) === ""}
                onClick={() => {
                    customFormikContext.setStepUpdateAttempt(
                        customFormikContext.stepUpdateAttempt + 1
                    );
                    handlePageChange(step + 1);
                }}
            >
                <FormattedText style={buttonTextStyle} text="Forward" />
                <NextArrow style={{ marginLeft: "3%" }} />
            </Button>
        );
    };

    return (
        <Paper
            elevation={2}
            style={{
                bottom: "0%",
                left: "0%",
                width: "100%",
                boxShadow: "0px -10px rgb(236, 236, 236, 0.4)",
            }}
        >
            <Grid container>
                {Cookies.get("facilityName") !== "Dentaprime London" && (
                    <Grid item xs={12}>
                        <FormattedText text={`${step + 1}/${stepsLength}`} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ProgressBar currentStep={step} steps={stepsLength} />
                </Grid>
                {(step === consentHide || step === declarationHide) ? (
                    <Grid item xs={12}>
                        <BackButton />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={6}>
                            <BackButton />
                        </Grid>
                        <Grid item xs={6}>
                            <NextButton />
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
};

export default Stepper;
