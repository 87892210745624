import React, { ReactElement, useState, useRef } from "react";
import {
    FormControl, FormHelperText, FormLabel,
    Radio, RadioGroup, ThemeProvider
} from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DentaprimeTheme from "../../../../../../Styles";


function RadioComponent(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any;
    questionWidth: any;
    name: any;
    answerLenght: any;
    mapAnswers: any;
    emptyField: any;
}
): ReactElement {
    return (
        <ThemeProvider theme={DentaprimeTheme}>
            <Grid
                container
                item
                xs={12}
                style={{
                    textAlign: "left",
                }}
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        textAlign: "left",
                    }}
                >
                    <FormControl
                        style={{
                            textAlign: "left",
                        }}
                        error={Boolean(props.inputValue === "")}
                        component="fieldset" >
                        <FormLabel>
                            <Typography
                                variant='subtitle1'>
                                {props.label}
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            row={props.answerLenght}
                            id={props.name}
                            name={props.name}
                            onBlur={() => { props.setBlur(props.blurValue + 1); }}
                            onChange={(val: { target: { value: any; }; }) => {
                                props.setInputValue(val.target.value);
                                (document.activeElement as HTMLElement).blur();
                            }}
                            value={props.inputValue || ""}
                        >
                            {props.mapAnswers?.map((i: { text: string }) =>
                                <FormControlLabel
                                    key={i.text}
                                    value={i.text}
                                    control={<Radio color="primary" />}
                                    label={i.text}
                                />)}
                        </RadioGroup>
                        {Boolean(props.inputValue === "") &&
                            <FormHelperText>
                                <Typography>
                                    {props.emptyField}
                                </Typography>
                            </FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default RadioComponent;