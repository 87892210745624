import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  loadTinyFaceDetectorModel,
  detectSingleFace,
  detectAllFaces,
  TinyFaceDetectorOptions
} from "face-api.js";
import { useTranslation } from "react-i18next";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Camera } from "react-camera-pro";
import Webcam from "react-webcam";
import { sendProfilePictureToWF } from "../../Providers/backend";

function FaceCapture(props: {
  patient?: string;
  step: number;
  onStepChange: (pageNum: React.SetStateAction<number>) => void;
  isRendered: (pageNum: React.SetStateAction<boolean>) => void;
}) {
  //references and states used in the component
  const { t } = useTranslation();
  const [video, setVideo]: any = useState(null);
  const [canvas, setCanvas]: any = useState(null);
  const [detected, setDetected]: any = useState(false);
  const [camera, setCamera]: any = useState(false);
  const [imageSrc, setImageSrc]: any = useState("");
  const [check1, setCheck1] = useState(false);
  const videoRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const webcamRef: any = useRef(null);
  const [image, setImage] = useState(null);

  //capture a picture
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef, setImage]);

  useEffect(() => {
    setVideo(videoRef.current);
    setCanvas(canvasRef.current);
    setCheck1(!check1);
  }, []);

  //stop camera manually or after capturing a picture
  const stopCam = () => {
    const stream = webcamRef.current.stream;
    video.srcObject.getTracks()[0].stop();
    setCamera(false);
  };

  // start face detection
  const start = async () => {
    await launchCamera();
    const recognition = makeRecognition();
    await recognition.init();
    recognition.start();
  };

  //face-api.js definition
  const getFaceDetectorOptions = () =>
    new TinyFaceDetectorOptions({ inputSize: 224, scoreThreshold: 0.5 });
  const makeRecognition = () => {
    let ctx: any;
    const init = async () => {
      await loadTinyFaceDetectorModel("models");
      ctx = canvas.getContext("2d");
    };

    //start face detection function
    const start = async () => {
      await wait(0);
      if (video.readyState === 4) {
        const faces = await detectSingleFace(video, getFaceDetectorOptions());
        const fullFaceDescriptions = await detectAllFaces(video, getFaceDetectorOptions());
        if ((faces) && (fullFaceDescriptions.length === 1)) {
          setDetected(true);
        } else {
          setDetected(false);
          ctx.clearRect(0, 0, video.videoWidth, video.videoHeight);
        }
      }
      start();
    };

    return { init, start };
  };
  const videoConstraints = {
    width: 220,
    height: 300,
    facingMode: "user"
  };
  //launch UI camera
  const launchCamera = () =>
    new Promise((resolve: any) => {
      (navigator.mediaDevices as any)
        .getUserMedia({
          audio: false,
          video: {
            mandatory: {
              minWidth: 500,
              maxWidth: 500,
              minHeight: 320,
              maxHeight: 320,
              minFrameRate: 1,
              maxFrameRate: 10
            }
          }
        })
        .then(
          (stream: any) => {
            video.srcObject = stream;
            video.play();
            setCamera(true);
            resolve();
          },
          // eslint-disable-next-line 
          () => { }
        );
    });
  return (
    <>
      <Typography variant="h5" component="div" align="center"
        style={{ fontWeight: "bold", marginBottom: "3vh" }}>
        {t("pressLaunch")}
      </Typography>
      <div
        style={{
          margin: "auto",
        }}
      >
        {/* image visualization after picture is taken */}
        {/* start camera Button */}
        <Grid>
          <FormControlLabel control={
            <Grid container>
              <Grid item xs={1}>
                <Checkbox checked={!check1} onChange={() => {
                  setCheck1(!check1);
                  if (check1 === false) {
                    setImageSrc("");
                    video.srcObject.getTracks()[0].stop();
                    setCamera(false);
                  } else {
                    start();
                    window.scrollTo(0, document.body.scrollHeight);
                  }
                }} />

              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body1" gutterBottom component="div" align="justify"
                >
                  {t("confirmPic")}
                </Typography>
              </Grid>
            </Grid>
          } label="" style={{ minWidth: 125 }} />
        </Grid>
        {!camera ? (
          <Box mt={1}>
            {/* render image if there is no camera and has taken picture */}
            {imageSrc && !camera ? (
              <img className="" src={imageSrc} alt="yourimage" />
            ) : null}
            {imageSrc ?
              <Box>
                <Button
                  style={imageSrc ? {
                    padding: 20,
                    fontSize: 14,
                    background: "#6d6d86",
                    color: "#fff",
                    margin: "auto",
                    borderRadius: 20
                  } : {
                    padding: 20,
                    fontSize: 14,
                    backgroundColor: "#8d2d46",
                    color: "#fff",
                    margin: "auto",
                    borderRadius: 20
                  }}
                  onClick={() => {
                    start();
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                >
                  {t("retake")}
                </Button>
                <Button style={{
                  padding: 20,
                  fontSize: 14,
                  backgroundColor: "#8d2d46",
                  color: "#fff",
                  margin: "auto",
                  borderRadius: 20
                }}
                  onClick={() => {
                    // removes first 23 symbols to take only base64 string for the pdf
                    sendProfilePictureToWF(imageSrc.slice(23),
                      `p${props.patient}.jpg`, props.patient + "");
                    video.srcObject.getTracks()[0].stop();
                    props.isRendered(false);
                  }}

                >{t("sendPic")}</Button> </Box> : null}
          </Box>
        ) : (
          <Box>
            <Box >
              {/* camera for capturing picture */}
              <Webcam
                audio={false}
                ref={webcamRef}
                mirrored={true}
                screenshotFormat="image/jpeg"
                screenshotQuality={0.92}
                minScreenshotHeight={250}
                imageSmoothing={true}
                minScreenshotWidth={155}
                videoConstraints={
                  videoConstraints
                }
              />
            </Box>
            <Typography variant="subtitle1" component="div" align="center"
              style={{ fontWeight: "bold", marginTop: "2vh" }}>
              {t("cameraInstruction")}
            </Typography>
          </Box>
        )}
        {/* camera needs video to start camera, and canvas to take face detection.*/}
        <video
          style={{
            position: "absolute",
            top: 70,
            left: 10,
            visibility: "hidden"
          }}
          ref={videoRef}
        />
        <canvas
          style={{
            position: "absolute",
            top: 70,
            left: 10,
            visibility: "hidden"
          }}
          ref={canvasRef}
        />
        {/* if camera is true then show this Button to stop camera or don't visualize it*/}
        <Box mt={2}>
          {(camera) ? (
            <Button
              style={{
                padding: 20,
                fontSize: 14,
                background: "#6d6d86",
                color: "#fff",
                margin: "auto",
                borderRadius: 70
              }}
              onClick={() => {
                setImageSrc("");
                stopCam();
                video.srcObject.getTracks()[0].stop();
                setCamera(false);
                setCheck1(!check1);
              }}
            >
              {t("stopCam")}
            </Button>
          ) : null}
          {/* if face is detected and camera is on */}
          {/* shows Button for photo capturing */}
          {(camera) ?
            (detected ?
              <Button
                style={{
                  padding: 20,
                  fontSize: 14,
                  backgroundColor: "#8d2d46",
                  color: "#fff",
                  margin: "auto",
                  borderRadius: 70,
                }}
                onClick={() => {
                  setImageSrc(image);
                  capture();
                  stopCam();
                  setCamera(false);
                  setDetected(false);
                }}
              >
                {t("takePic")}
              </Button>
              :
              <Button
                style={{
                  padding: 20,
                  fontSize: 14,
                  background: "#b68d98",
                  color: "#fff",
                  margin: "auto",
                  borderRadius: 70,
                }}
                disabled
                //eslint-disable-next-line 
                onClick={() => {
                }}
              >
                {t("takePic")}
              </Button>) : null}
        </Box>
      </div>
      <Typography variant="caption" component="div"
        align="left" style={{ marginTop: "2vh", fontStyle: "italic" }}>
        {t("faceRecognitionInfo")}
      </Typography>
    </>
  );
}
// use it as async function to start face detection
const wait = (time: any) => new Promise((resolve) => setTimeout(resolve, time));

export default FaceCapture;