import React, { ReactElement, useRef } from "react";
import { FormLabel, TextField } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FormHelperText, Input } from "@material-ui/core";

function TextComponents(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any;
    name: any;
    placeholder: any;
    type: string;
    emptyField: any;
}
): ReactElement {
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const activeElement = document.activeElement as HTMLInputElement;
            activeElement.blur();
        }
    }
    return (
        <Grid
            item
            xs={12}
            style={{
                textAlign: "left",
            }}
        >
            <FormLabel>
                <Typography variant='subtitle1'>
                    {props.label}
                </Typography>
            </FormLabel>
            <Input
                onBlur={() => props.setBlur(props.blurValue + 1)}
                fullWidth={true}
                id={props.name}
                name={props.name}
                placeholder={props.placeholder}
                onChange={(val) => {
                    props.setInputValue(val.target.value);
                }}
                value={props.inputValue || ""}
                error={Boolean(props.inputValue === "")}
                onKeyDownCapture={handleKeyPress}
                type={props.type} />
            {Boolean(props.inputValue === "") &&
                <FormHelperText>
                    <Typography>
                        {props.emptyField}
                    </Typography>
                </FormHelperText>}
        </Grid>
    );
}

export default TextComponents;