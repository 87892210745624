import React, { ReactElement, useState, useRef, useEffect } from "react";
import { Box, Grid, List, ListItem, Typography, Button, Checkbox, Paper } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import {
    Dialog, DialogActions, DialogTitle, DialogContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import {
    Page, Text, View, Document, Image,
    PDFDownloadLink, StyleSheet, Font, pdf, PDFViewer
} from "@react-pdf/renderer";
import logo from "../ImageAgreement/Assets/logo.png";
import Cookies from "js-cookie";
import { PopupAlert } from "../../../../Hooks";
import checkedConsent from "../XRayConsent/Assets/square-xmark-solid.png";
import uncheckedConsent from "../XRayConsent/Assets/square-regular.png";
import { sendFileToSF, sendFileToServiceOS } from "../../../../Providers/backend";
import data from './text.json';

function ConsentUK(props: {
    questions: any;
    patient?: string;
    step: number;
    onStepChange: (pageNum: React.SetStateAction<number>) => void;
    isRendered: (pageNum: React.SetStateAction<boolean>) => void;
}
): ReactElement {
    const [check1, setCheck1] = useState(true);
    const [check2, setCheck2] = useState(true);
    const [check3, setCheck3] = useState(true);
    const [check4, setCheck4] = useState(false);
    const [check5, setCheck5] = useState(true);
    const [check6, setCheck6] = useState(false);
    const [check7, setCheck7] = useState(true);

    const patientNameRender =
        props.questions[1].answer.answerValue +
        " " +
        props.questions[2].answer.answerValue;
    let patientID;
    if (props.patient !== null || props.patient != undefined) {
        patientID = props.patient;
    }
    else {
        patientID = "";
    }
    const todayDate = new Date().toLocaleDateString("en-GB");
    let blobURL;
    const reader = new FileReader();
    const sigCanvas: any = useRef({});
    const [count, setCount] = useState(0);
    const { t } = useTranslation();
    const namingPDF = "Declaration of informed consent photography and video_" + patientNameRender + "_" + Date.now() + ".pdf";
    Font.register({
        family: "RobotoLight",
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
    });
    Font.register({
        family: "RobotoBlack",
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-black-webfont.ttf",
    });
    Font.register({
        family: "Mulish",
        src: "https://db.onlinewebfonts.com/t/9c16a93aac400c225cd618bf9f14e92e.ttf",
    });
    const styles = StyleSheet.create({
        page: {
            fontFamily: "Mulish",
            fontWeight: 500
        },
        inline: {
            height: 50,
            width: 200,
            margin: 15,
        },
        document: {
            height: "40%",
            width: "70%",
            margin: "0 auto",
        }
    });
    const [signature, setSignature] = useState(document.
        querySelector("canvas")?.toDataURL("image/png"));
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleSignatureEnd = () => {
        setSignature(sigCanvas.current.toDataURL());
    };
    const signatureValidatior = () => {
        if (sigCanvas.current.isEmpty() === true) {
            PopupAlert(
                "The signature must not be empty",
                "error"
            );

        } else {
            PDFFile();
            setOpen(true);
        }
    };
    useEffect(() => {
        if (window) {
            PDFFile();
        }
    }, []);

    const uploadFile = async () => {
        //generate blob from pdf
        const pdfBlob = await pdf(<PDFFile />).toBlob();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = async function () {
            const cutFileString = String(reader.result);
            await sendFileToServiceOS(
                cutFileString,
                "Declaration of informed consent photography and video_",
                patientNameRender,
                Cookies.get("sid") + ""
            )
        };
    };
    const PDFFile = () => (
        <Document title={namingPDF}>
            <Page size="A4" style={styles.page}>
                <View style={styles.inline}>
                    <Image src={logo} />
                </View>
                <View style={{ width: "95%", margin: "10 auto" }}>
                    <Text style={{ fontSize: 20, margin: "0 auto" }}>Informed consent</Text>
                    {[data.paragraph1, data.paragraph2, data.paragraph3, data.paragraph4, data.paragraph5, data.paragraph6, data.paragraph7].map((paragraph, index) => (
                        <View key={index} style={{ flexDirection: "row", margin: "10 auto", width: "90%" }}>
                            <Image
                                src={eval(`check${index + 1}`) ? checkedConsent : uncheckedConsent}
                                style={{ height: 15, width: 15 }}
                            />
                            <Text style={{ margin: 5, fontSize: 9, width: "90%" }}>{paragraph}</Text>
                        </View>
                    ))}
                </View>
                <View style={{ textAlign: "justify", margin: 40 }}>
                    <Text>{patientNameRender}</Text>
                </View>
                <View style={{ margin: "30 5", flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ fontSize: 12 }}>
                        <Text>London</Text>
                        <Text>{todayDate}</Text>
                    </View>
                    <View style={{ width: 50 }} />
                    {sigCanvas.current?.isEmpty() === false && (
                        <View style={{ alignItems: "flex-end", fontSize: 12 }}>
                            <Text style={{ marginBottom: 3 }}>Signature/signed on behalf by guardian/carer</Text>
                            <Image src={signature} style={{ width: 200, height: 100 }} />
                        </View>
                    )}
                </View>
            </Page>
        </Document>
    );
    const signatureSizeH = window.innerHeight * 0.3;
    const signatureSizeW = window.innerWidth * 0.8;

    return (
        <List>
            <Box sx={{
                margin: "auto",
                padding: "0 0.5em",
            }}>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check1}
                            disabled={true}
                            onChange={() => {
                                setCheck1(!check1);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph1}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check2}
                            disabled={true}
                            onChange={() => {
                                setCheck2(!check2);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph2}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check3}
                            disabled={false}
                            onChange={() => {
                                setCheck3(!check3);
                                setCheck4(false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph3}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check4}
                            disabled={false}
                            onChange={() => {
                                setCheck3(false);
                                setCheck4(!check4);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph4}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check5}
                            disabled={false}
                            onChange={() => {
                                setCheck5(!check5);
                                setCheck6(false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph5}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check6}
                            disabled={false}
                            onChange={() => {
                                setCheck5(false);
                                setCheck6(!check6);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph6}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={check7}
                            disabled={true}
                            onChange={() => {
                                setCheck7(!check7);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {data.paragraph7}
                        </Typography>
                    </Grid>
                </Grid>
                <ListItem alignItems="center">
                    <Box sx={{
                        border: "3px solid grey",
                        margin: "auto",
                        borderRadius: 10,
                    }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Signature/signed on behalf by guardian/carer
                        </Typography>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="red"
                            canvasProps={{
                                width: signatureSizeW, height: signatureSizeH,
                                className: "sigCanvas"
                            }}
                            backgroundColor="#f4f5f8"
                            onEnd={handleSignatureEnd}
                        />
                        <Grid>
                            <Typography variant="h6" gutterBottom component="h6">
                                London,
                            </Typography>
                            <Typography variant="h6" gutterBottom component="h6">
                                {todayDate}
                            </Typography>
                        </Grid>
                    </Box>
                </ListItem>
            </Box>
            <Box sx={{
                position: "sticky",
                top: "15px"
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button style={{
                            width: "100%",
                            height: "6.25vh",
                            borderRadius: "0",
                            color: "#fff",
                            background:
                                "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)",
                        }} onClick={() => {
                            sigCanvas.current.clear();
                        }}>{t("Clear")}
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button style={{
                            width: "100%",
                            height: "6.25vh",
                            borderRadius: "0",
                            color: "#fff",
                        }} onClick={() => {
                            // eslint-disable-next-line max-len
                            setSignature(document.querySelector("canvas")?.toDataURL("image/png"));
                            signatureValidatior();
                        }}>{t("Continue")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Are you sure you want to submit?")}</DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <PDFDownloadLink document={<PDFFile />} fileName={namingPDF}>
                        {({ blob }) => {
                            blobURL = blob;
                        }}
                    </PDFDownloadLink>
                    <Button
                        onClick={() => {
                            setCount(count + 1);
                            setTimeout(() => {
                                uploadFile();
                                setOpen(false);
                                props.isRendered(false);
                                window.scrollTo(0, 0);
                            }, 1000);
                        }}>{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </List>
    );

}
export default ConsentUK;