import React, { ReactElement, useState, useRef } from "react";
import { Box, Grid, List, ListItem, Typography, Button, Checkbox, Paper, ListItemText } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import {
    Dialog, DialogActions, DialogTitle, DialogContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import {
    Page, Text, View, Document, Image,
    PDFDownloadLink, StyleSheet, Font, pdf, PDFViewer
} from "@react-pdf/renderer";
import logo from "../ImageAgreement/Assets/logo.png";
import Cookies from "js-cookie";
import { PopupAlert } from "../../../../Hooks";
import { sendFileToSF, sendFileToServiceOS } from "../../../../Providers/backend";
import checkedConsent from "../XRayConsent/Assets/square-xmark-solid.png";
import uncheckedConsent from "../XRayConsent/Assets/square-regular.png";
import data from "./text.json";
import CircleIcon from "@mui/icons-material/Circle";


function CBCTScan(props: {
    questions: any;
    patient?: string;
    step: number;
    onStepChange: (pageNum: React.SetStateAction<number>) => void;
    isRendered: (pageNum: React.SetStateAction<boolean>) => void;
}
): ReactElement {
    const [check1, setCheck1] = useState(true);
    const [check2, setCheck2] = useState(false);
    const patientNameRender =
        props.questions[1].answer.answerValue +
        " " +
        props.questions[2].answer.answerValue;
    let patientID: any;
    if (props.patient !== null || props.patient != undefined) {
        patientID = props.patient;
    }
    else {
        patientID = "";
    }
    const todayDate = new Date().toLocaleDateString("en-GB");
    let blobURL;
    const reader = new FileReader();
    const sigCanvas: any = useRef({});
    const [count, setCount] = useState(0);
    const [isSigned, setIsSigned] = useState<boolean>(false);
    const { t } = useTranslation();
    const namingPDF = "CBCT informed consent-" + patientNameRender + "-" + todayDate + ".pdf";
    Font.register({
        family: "RobotoLight",
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
    });
    Font.register({
        family: "Roboto",
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-black-webfont.ttf",
    });
    Font.register({
        family: "Mulish",
        src: "https://db.onlinewebfonts.com/t/9c16a93aac400c225cd618bf9f14e92e.ttf",
    });
    Font.register({
        family: "MontserratRegular",
        src: "https://cdn.jsdelivr.net/npm/@expo-google-fonts/montserrat@0.2.3/Montserrat_500Medium.ttf",
    });

    const styles = StyleSheet.create({
        page: { fontWeight: 500, fontFamily: "Mulish" },
        inline: { height: 50, width: 150, margin: "0 auto" },
        title: { marginBottom: 10, textAlign: "center" },
        paragraph: { margin: "3 auto", fontSize: 9, width: "90%" },
        bulletPoint: { margin: "2 auto", fontSize: 8, width: "85%" },
        icon: { maxHeight: 5 },
        consentSection: { width: "95%", margin: "0 auto" },
        consentRow: { flexDirection: "row", margin: "5 auto", width: "90%" },
        checkboxImage: { height: 10, width: 10 },
        consentText: { marginLeft: 5, fontSize: 8 },
        signatureSection: { margin: 5, flexDirection: "row", justifyContent: "space-around" },
        signature: { alignItems: "flex-end", fontSize: 12, marginRight: 5, marginBottom: 5 },
        signatureImage: { width: 200, height: 100 },
    });
    const [signature, setSignature] = useState(document.
        querySelector("canvas")?.toDataURL("image/png"));
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleSignatureEnd = () => {
        setSignature(sigCanvas.current.toDataURL());
    };
    const checkLocation = Cookies.get("facilityName") === "Dentaprime%20London" ||
        Cookies.get("facilityName") === "Dentaprime London";
    const signatureValidatior = () => {
        if (sigCanvas.current.isEmpty() === true) {
            PopupAlert(
                "The signature must not be empty",
                "error"
            );

        } else {
            PDFFile();
            setOpen(true);
        }
    };
    window.onload = (event) => {
        PDFFile();
    };

    const uploadFile = async () => {
        //generate blob from pdf
        const pdfBlob = await pdf(<PDFFile />).toBlob();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = async function () {
            const cutFileString = String(reader.result);
            await sendFileToServiceOS(
                cutFileString,
                "CBCT informed consent-",
                patientNameRender,
                Cookies.get("sid") + ""
            )
        };
    };
    const renderBulletPoints = () => {
        return (
            <>
                <Text style={styles.bulletPoint}><CircleIcon style={styles.icon} />. {data.paragraph7}</Text>
                <Text style={styles.bulletPoint}><CircleIcon style={styles.icon} />. {data.paragraph8}</Text>
                <Text style={styles.bulletPoint}><CircleIcon style={styles.icon} />. {data.paragraph9}</Text>
                <Text style={styles.bulletPoint}><CircleIcon style={styles.icon} />. {data.paragraph10}</Text>
                <Text style={styles.bulletPoint}><CircleIcon style={styles.icon} />. {data.paragraph11}</Text>
            </>
        );
    };
    const PDFFile = () => (
        <Document title={namingPDF}>
            <Page size="A4" style={styles.page}>
                <View style={styles.inline}>
                    <Image src={logo} />
                </View>
                <Text style={styles.title}>Informed Consent Cone Beam Computed Tomography treatment</Text>
                <View>
                    <Text style={styles.paragraph}>{`A CBCT scan, also known as a Cone Beam Computed Tomography, ${data.paragraph3}`}</Text>
                    <Text style={styles.paragraph}>{data.paragraph4}</Text>
                    <Text style={styles.paragraph}>{data.paragraph5}</Text>
                    <Text style={styles.paragraph}>{`Advantages of a CBCT scan over conventional x-rays: ${data.paragraph6}`}</Text>
                    {renderBulletPoints()}
                    <Text style={styles.paragraph}>{`Radiation: ${data.paragraph12}`}</Text>
                    <Text style={styles.paragraph}>{`Pregnancy: ${data.paragraph13}`}</Text>
                    <Text style={styles.paragraph}>{`Diagnosis of non-dental conditions: ${data.paragraph14}`}</Text>
                    <Text style={styles.paragraph}>{`Possible side effects or complications associated with the planned treatment: ${data.paragraph15}`}</Text>
                    <Text style={styles.paragraph}>{`Are there any alternatives? ${data.paragraph16}`}</Text>
                </View>
                <ConsentSection check1={check1} check2={check2} patientNameRender={patientNameRender} />
                <View style={styles.signatureSection}>
                    <Text>London</Text>
                    <Text>{todayDate}</Text>
                    {!sigCanvas.current.isEmpty() && (
                        <View style={styles.signature}>
                            <Text>Signature/signed on behalf by guardian/carer</Text>
                            <Image src={signature} style={styles.signatureImage} />
                        </View>
                    )}
                </View>
            </Page>
        </Document>
    );
    const ConsentSection = ({ check1, check2, patientNameRender }: { check1: boolean; check2: boolean; patientNameRender: string; }) => {
        return (
            <View style={styles.consentSection}>
                <View style={styles.consentRow}>
                    <Image src={check1 ? checkedConsent : uncheckedConsent} style={styles.checkboxImage} />
                    <Text style={styles.consentText}>{`I, ${patientNameRender} ${data.paragraph1}`}</Text>
                </View>
                <View style={styles.consentRow}>
                    <Image src={check1 ? checkedConsent : uncheckedConsent} style={styles.checkboxImage} />
                    <Text style={styles.consentText}>{`I, ${patientNameRender} ${data.paragraph17}`}</Text>
                </View>
                <View style={styles.consentRow}>
                    <Image src={check2 ? checkedConsent : uncheckedConsent} style={styles.checkboxImage} />
                    <Text style={styles.consentText}>{data.paragraph2}</Text>
                </View>
            </View>
        );
    };
    const signatureSizeH = window.innerHeight * 0.3;
    const signatureSizeW = window.innerWidth * 0.8;

    return (
        <List>
            <Box sx={{
                margin: "auto",
                padding: "0 0.5em",
            }}>
                <Paper variant="outlined" style={{ marginBottom: 5 }}>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {`A CBCT scan, also known as a Cone Beam Computed Tomography, ${data.paragraph3}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {data.paragraph4}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {data.paragraph5}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ margin: 5 }}>
                        {`Advantages of a CBCT scan over conventional x-rays: ${data.paragraph6}`}
                    </Typography>
                    <List style={{ textAlign: "center" }}>
                        <ListItem><CircleIcon style={{ maxHeight: 5 }} /><ListItemText> {data.paragraph7}</ListItemText></ListItem>
                        <ListItem><CircleIcon style={{ maxHeight: 5 }} /><ListItemText> {data.paragraph8}</ListItemText></ListItem>
                        <ListItem><CircleIcon style={{ maxHeight: 5 }} /><ListItemText> {data.paragraph9}</ListItemText></ListItem>
                        <ListItem><CircleIcon style={{ maxHeight: 5 }} /><ListItemText> {data.paragraph10}</ListItemText></ListItem>
                        <ListItem><CircleIcon style={{ maxHeight: 5 }} /><ListItemText> {data.paragraph11}</ListItemText></ListItem>
                    </List>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {`Radiation: ${data.paragraph12}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {`Pregnancy: ${data.paragraph13}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {`Diagnosis of non-dental conditions: ${data.paragraph14}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {`Possible side effects or complications associated with the planned treatment: ${data.paragraph15}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify" style={{ marginBottom: 5 }}>
                        {`Are there any alternatives? ${data.paragraph16}`}
                    </Typography>
                </Paper>
                <Paper>
                    <List style={{ marginBottom: 5 }}>
                        <ListItem>
                            <Checkbox
                                checked={check1} disabled onChange={() => {
                                    setCheck1(!check1);
                                    setCheck2(false);
                                }} />
                            <ListItemText>{`I, ${patientNameRender} ${data.paragraph1}`}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <Checkbox checked={check1} disabled onChange={() => setCheck1(!check1)} />
                            <ListItemText>{`I, ${patientNameRender} ${data.paragraph17}`}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <Checkbox checked={check2} disabled onChange={() => {
                                setCheck2(!check2);
                                check2 === true ? setCheck1(true) : setCheck1(false);
                            }} />
                            <ListItemText>{data.paragraph2}</ListItemText>
                        </ListItem>
                    </List>
                </Paper>
                <ListItem alignItems="center">
                    <Box sx={{
                        border: "3px solid grey",
                        margin: "auto",
                        borderRadius: 10,
                    }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Signature/signed on behalf by guardian/carer
                        </Typography>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="red"
                            canvasProps={{
                                width: signatureSizeW, height: signatureSizeH,
                                className: "sigCanvas"
                            }}
                            backgroundColor="#f4f5f8"
                            onEnd={handleSignatureEnd}
                        />
                        <Grid>
                            <Typography variant="h6" gutterBottom component="h6">
                                London,
                            </Typography>
                            <Typography variant="h6" gutterBottom component="h6">
                                {todayDate}
                            </Typography>
                        </Grid>
                    </Box>
                </ListItem>
            </Box>
            <Box sx={{
                position: "sticky",
                top: "15px"
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button style={{
                            width: "100%",
                            height: "6.25vh",
                            borderRadius: "0",
                            color: "#fff",
                            background:
                                "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)",
                        }} onClick={() => {
                            sigCanvas.current.clear();
                        }}>{t("Clear")}
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button style={{
                            width: "100%",
                            height: "6.25vh",
                            borderRadius: "0",
                            color: "#fff",
                        }} onClick={() => {
                            // eslint-disable-next-line max-len
                            setSignature(document.querySelector("canvas")?.toDataURL("image/png"));
                            signatureValidatior();
                        }}>{t("Continue")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Are you sure you want to submit?")}</DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <PDFDownloadLink document={<PDFFile />} fileName={namingPDF}>
                        {({ blob }) => {
                            blobURL = blob;
                        }}
                    </PDFDownloadLink>
                    <Button
                        onClick={() => {
                            setCount(count + 1);
                            setTimeout(() => {
                                uploadFile();
                                setOpen(false);
                                props.isRendered(false);
                                window.scrollTo(0, 0);
                            }, 1000);
                        }}>{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </List>
    );

}
export default CBCTScan;