import { useFormikContext } from "formik";
import React, { FunctionComponent, ReactNode, useContext, useEffect, useState } from "react";
import { FormikCtx, IFormikContext } from "../../../../Contexts/formikContext";

interface GridWrapperProps {
    children: ReactNode
}


const GridWrapper: FunctionComponent<GridWrapperProps> = ({children}) =>{
    const customFormikContext = useContext<IFormikContext>(FormikCtx);
    const [sideEffectTrigger, setSideEffectTrigger] = useState<number>(0);

    const { getFieldProps, setFieldValue, setFieldError, values } = useFormikContext();

    useEffect(() => {
        if(Object.keys(customFormikContext.updateFunctions).length){
            setSideEffectTrigger(sideEffectTrigger + 1);
        }},[customFormikContext.updateFunctions]);

    useEffect(() =>{
        (async() =>{
            for (const key in customFormikContext.updateFunctions) {
                const element =  customFormikContext.updateFunctions[key];
                await setFieldValue(element.name, element.value);
            }
            customFormikContext.setUpdateFunctions({});
        })();
    }, [customFormikContext.step, sideEffectTrigger]);
    // step update attempt is triggered before validation


    return <>{children}</>;
};

export default GridWrapper;