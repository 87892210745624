import { Grid, Snackbar } from '@mui/material'
import { Alert, Button, TextField } from '@mui/material'
import axios from 'axios'
import React, { SetStateAction, useEffect, useState } from 'react'
import { BACKEND_URL } from '../../Resources/constants'
import { getIDFromWF } from '../../Providers/backend'

function Encryption() {
    const digitsOnlyRegex = /^\d+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = (email: string) => {
        return emailRegex.test(email);
    };
    const containsOnlyDigits = (inputString: string) => {
        return digitsOnlyRegex.test(inputString);
    };
    const [orisID, setOrisID] = useState("")
    const [email, setEmail] = useState("")
    const [sended, setSended] = useState("")
    const [openError, setOpenError] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const sendEmail = (id: any, email: any) => {
        getIDFromWF(orisID).then((response: any) => {
            axios({
                url: BACKEND_URL + "/encrypt",
                method: "POST",
                data: {
                    id: response.data.token,
                    email: email,
                }
            }).then((res) => { setSended("Sended!"); setOpenSuccess(true); setOpenError(true); })
                .catch(err => { setSended("Problem with sending! Please, try again!"); setOpenSuccess(false); setOpenError(true); })
        });

    }

    useEffect(() => {

    }, [orisID, email, openError]);
    const handleCloseSnackbar = () => setOpenError(false);


    return (
        <Grid container xs={12}>
            <Snackbar
                open={openError}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={openSuccess ? "success" : "error"}
                    sx={{ width: '100%' }}
                >
                    {sended}
                </Alert>
            </Snackbar>
            <Grid container xs={12} sx={{ p: 3 }} alignItems="center" justifyContent="center">
                <TextField
                    variant="standard"
                    value={orisID}
                    label="Patient ID"
                    sx={{ width: 300 }}
                    onChange={(event) => setOrisID(event.target.value)}
                />
            </Grid>
            <Grid container xs={12} sx={{ p: 3 }} alignItems="center" justifyContent="center">
                <TextField
                    variant="standard"
                    value={email}
                    label="Email"
                    sx={{ width: 300 }}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </Grid>
            <Grid container xs={12} alignItems="center" justifyContent="center">
                <Button
                    variant="contained"
                    sx={{ background: "#8d2d46", m: 5 }}
                    disabled={!orisID || !email || !isValidEmail(email) || !containsOnlyDigits(orisID)}
                    onClick={() => {
                        sendEmail(orisID, email);
                        setOrisID("");
                        setEmail("");
                    }}
                >
                    Send Email
                </Button>
            </Grid>
        </Grid>
    );
}

export default Encryption;