import Alert from "./Components/Alert";
import { toast } from "react-toastify";

export const PopupAlert = (
    message = "Error",
    severity: "success" | "info" | "warning" | "error" = "error"
): void => {
    toast(
        Alert(message, severity),
        { style: { borderRadius: "30px" } }
    );
};