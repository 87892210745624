import React, { createContext } from "react";

//formik interface
export type IFormikContext = {
    updateFunctions: Record<string, {
        name: string,
        value: any
    }>
    setUpdateFunctions: 
        React.Dispatch<React.SetStateAction<Record<string, {name: string, value: any}>>>

    step: number
    setStep: React.Dispatch<React.SetStateAction<number>>
    stepUpdateAttempt: number
    setStepUpdateAttempt: React.Dispatch<React.SetStateAction<number>>
}

// formik context query the context state
export const DefaultFormikContext: IFormikContext = {
    updateFunctions: {},
    setUpdateFunctions: () => {return;},
    step: 0,
    setStep: () => {return;},
    stepUpdateAttempt: 0,
    setStepUpdateAttempt:() => {return;}
};

//create formik context and passing Formik props
export const FormikCtx = createContext<IFormikContext>(DefaultFormikContext);