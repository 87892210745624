import React, { ReactElement } from "react";
import { FormControl, FormLabel } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReactPhoneInput from "react-phone-input-2";
import FormHelperText from "@material-ui/core/FormHelperText";

function PhoneComponent(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any;
    questionWidth: any;
    name: any;
    placeholder: any;
    emptyField: any;
}
): ReactElement {
    return (
        <Grid container xs={12}
            sm={props.questionWidth}>
            <Grid item xs={6} style={{
                textAlign: "left",
            }}
            >
                <FormLabel>
                    <Typography variant='subtitle1'>
                        {props.label}
                    </Typography>
                </FormLabel>
                <ReactPhoneInput
                    onBlur={() => { props.setBlur(props.blurValue + 1); }}
                    preferredCountries={["de", "bg", "gb", "ch", "us", "ru", "se", "at"]}
                    country={((props.label.match("Телефонен номер")) ? "bg" : (props.label.match("Телефон")) ? "ru" :
                        (props.label.includes("Telefonnummer") ? "de" : "gb"))}
                    prefix={"+"}
                    placeholder={props.placeholder}
                    dropdownStyle={{ height: "150px" }}
                    enableSearch={true}
                    countryCodeEditable={(props.inputValue.substring(1, 2) !== "0") ? false : true}
                    inputStyle={{ width: "100%", alignItems: "left", height: "50px" }}
                    onChange={(val) => {
                        props.setInputValue("+" + val);
                    }}
                    value={(props.inputValue.startsWith("00") ? props.inputValue.slice(2) : props.inputValue.slice(1)) || ""}
                />
                {Boolean(props.inputValue === "") &&
                    <FormHelperText>
                        <Typography>
                            {props.emptyField}
                        </Typography>
                    </FormHelperText>}
            </Grid>

        </Grid>
    );
}

export default PhoneComponent;