import React from "react";
import { Alert, Color } from "@material-ui/lab";
import FormattedText from "../FormattedText";
import { ReactElement } from "react";
import { ReactComponent as SuccessIcon } from "./Assets/success.svg";
import { ReactComponent as ErrorIcon } from "./Assets/error.svg";

const CustomAlert = (
    message = "Error",
    severity: Color = "error"
): ReactElement => {
    return <Alert
        style={{
            borderRadius: "30px",
        }}
        iconMapping={{
            success: <SuccessIcon />,
            error: <ErrorIcon />
        }}
        severity={severity} >
        <FormattedText
            variant='subtitle1'
            style={{
                fontWeight: 500
            }}
            text={message} />
    </Alert >;
};

export default CustomAlert;