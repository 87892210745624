import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',
        margin: 'auto',
    },
    icon: {
        fontSize: 100,
        color: '#8d2d46', // Primary color
        fontWeight: 700,  // Bold font weight
    },
    title: {
        color: '#8d2d46', // Primary color
        fontWeight: 700,  // Bold font weight
    },
    message: {
        color: '#8d2d46', // Primary color
        fontWeight: 700,  // Bold font weight
    },
};

function AccessDeniedPage() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer); // Cleanup timeout on unmount
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Box sx={styles.container}>
            <Box>
                <WarningIcon sx={styles.icon} />
                <Typography variant="h3" gutterBottom sx={styles.title}>
                    Error
                </Typography>
            </Box>
            <Typography variant="h6" gutterBottom sx={styles.message}>
                {t('unavailable')}
            </Typography>
        </Box>
    );
}

export default AccessDeniedPage;
