import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { PopupAlert } from "../../Hooks";
import { checkID, getIDFromWF } from "../../Providers/backend";
import {PATIENT_ID_COOKIE} from "../../Resources/constants";

export const useOnSubmit = (): (val: {
    id: string;
}) => void => {

    const history = useHistory();

    return (val: { id: string; }) => {
        checkID(val.id.trim())
            .then(() => {
                Cookies.set(PATIENT_ID_COOKIE, val.id.trim());
                getIDFromWF(val.id.trim()).then((response:any)=>{
                    Cookies.set("sid",response.data.wfid+"");
                });
                history.push("/filledForm");
                history.goForward();
            })
            .catch(() => PopupAlert("Nonexistent ID", "error"));
    };
};
