import React, { ReactElement, useState, useRef } from "react";
import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DatePicker from "react-date-picker";

function CalendarComponent(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any;
    questionWidth: any;
}
): ReactElement {
    return (
        <Grid
            container
            item
            sm={props.questionWidth}
            justifyContent='center'
        >
            <Grid
                item
                xs={12}
                style={{
                    textAlign: "left",
                }}
            >
                <FormLabel>
                    <Typography variant='subtitle1'>
                        {props.label}
                    </Typography>
                </FormLabel>
                <Box mt={2}>
                    <DatePicker
                        format="dd/MM/yyyy"
                        showLeadingZeros={true}
                        minDate={new Date(1901, 1, 1, 5)}
                        maxDate={new Date()}
                        onChange={(val: any) => {
                            let dateISO;
                            if (val != null && val != "" && val != undefined &&
                                val != "0000-00-00") {
                                dateISO = new Date(val.getTime() + 21000000)
                                    .toISOString().split("T")[0];
                            } else {
                                dateISO = new Date().toISOString().split("T")[0];
                            }
                            const dateAddOne = dateISO.split("-").reverse();
                            props.setInputValue(dateAddOne.join("."));
                            props.setBlur(props.blurValue + 1);
                            (document.activeElement as HTMLElement).blur();
                        }}
                        value={
                            props.inputValue === "" ||
                                props.inputValue === null ||
                                props.inputValue == "00.00.0000" ?
                                new Date() :
                                new Date(new Date(props.inputValue.split(".")
                                    .reverse().join("-")))}
                        clearIcon={null}
                        isOpen={props.inputValue === "" ? true : false}
                        className="react-datepicker-wrapper"
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default CalendarComponent;