import React, { ReactElement, useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  List,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import { PopupAlert } from "../../../../Hooks";
import { sendFileToServiceOS } from "../../../../Providers/backend";
import Cookies from "js-cookie";
import checkedConsent from "./Assets/square-xmark-solid.png";
import uncheckedConsent from "./Assets/square-regular.png";

function XRayConsent(props: {
  patientName?: any;
  patientID: any;
  step: number;
  onStepChange: (pageNum: React.SetStateAction<number>) => void;
  isRendered: (pageNum: React.SetStateAction<boolean>) => void;
}): ReactElement {
  const today = new Date();
  const todayDate = today.toLocaleDateString();
  const currentDate = todayDate.replaceAll("г.", "").replaceAll(/[.]/g, "/");
  const patientNameRender =
    props.patientName[0].answer.answerValue +
    " " +
    props.patientName[1].answer.answerValue;
  const reader = new FileReader();
  const sigCanvas: any = useRef({});
  const { t } = useTranslation();
  const namingPDF = "roentgen_" + Date.now() + ".pdf";
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(true);
  const [check3, setCheck3] = useState(true);
  const [count, setCount] = useState(0);

  Font.register({
    family: "RobotoLight",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  });
  Font.register({
    family: "RobotoBlack",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-black-webfont.ttf",
  });

  const styles = StyleSheet.create({
    page: {
      fontFamily: "RobotoBlack",
      fontWeight: 900,
      fontSize: 10,
    },
    inline: {
      height: 90,
      width: 200,
      margin: "0 auto",
    },
    text: {
      margin: "auto 10",
      fontFamily: "RobotoLight",
    },
    divider: {
      width: "90vw",
      height: 1,
      margin: "0 auto",
      backgroundColor: "black",
    },

    consents: {
      flexDirection: "row",
    },
  });
  const [signature, setSignature] = useState(
    document.querySelector("canvas")?.toDataURL("image/png")
  );
  const handleSignatureEnd = () => {
    setSignature(sigCanvas.current.toDataURL());
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const signatureValidatior = () => {
    if (sigCanvas.current.isEmpty() === true && check3 === false) {
      PopupAlert("The signature must not be empty", "error");
    } else {
      setOpen(true);
    }
  };

  const determineClinic = () => {
    const agent = Cookies.get("Agent") || "";
    const facility = Cookies.get("facilityName") || "";
    if (facility?.includes("Dentaprime") && ["SDP-DACH", "SCH"].includes(agent)) {
      return "MDC";
    } else if (facility?.includes("Dentaprime") && agent === "SDP-Local") {
      return "Swiss";
    } else if (facility?.includes("Dentaprime London")) {
      return "London";
    } else {
      return "City";
    }
  };

  const clinic = determineClinic();

  //upload PDF file in Salesforce
  const uploadFile = async () => {
    //generate blob from pdf
    const pdfBlob = await pdf(<PDFFile />).toBlob();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = async function () {
      const cutFileString = String(reader.result);
      await sendFileToServiceOS(
        cutFileString,
        "roentgen_",
        patientNameRender,
        Cookies.get("sid") + ""
      )
    };
  };
  // generating PDF file
  const PDFFile = () => (
    <Document title={namingPDF + ""}>
      <Page size="A4" style={styles.page}>
        <View style={{ textAlign: "right", width: "95vw" }}>
          <Text>{t("documenttitle1CT")}</Text>
          <Text>
            {t("documenttitle2CT1")} {t("documenttitle2CT2")}
          </Text>
          <Text>{t("documenttitle3CT")}</Text>
        </View>
        <View style={styles.divider} />
        <View
          style={{
            textAlign: "justify",
            flexDirection: "row",
            border: "1px solid black",
            width: "90vw",
            margin: "10 auto",
          }}
        >
          <View style={{ alignItems: "center", width: "50vw" }}>
            <Text style={{ fontWeight: 900, textDecoration: "underline" }}>
              {patientNameRender}
            </Text>
          </View>
          <View
            style={{
              alignItems: "flex-end",
              width: "75vw",
              fontFamily: "RobotoLight",
            }}
          >
            <Text
              style={{ textDecoration: "underline", fontFamily: "RobotoBlack" }}
            >
              {t("klinik")}
            </Text>
            <Text>{t("clinic1CT" + clinic)}</Text>
            <Text>{t(clinic + "AddressCT")}</Text>
            <Text>{t(clinic + "CTPostalN")}</Text>
          </View>
        </View>
        <View>
          <Text style={{ margin: "auto 10" }}>{t("title1CT")}</Text>
          <Text style={styles.text}>{t("text1CT")}</Text>
          <Text style={styles.text}>{t("text2CT")}</Text>
          <Text style={styles.text}>{t("text3CT")}</Text>
          <Text style={{ margin: "5 10 0" }}>{t("title2CT")}</Text>
          <Text style={styles.text}>{t("text4CT")}</Text>
          <Text style={{ margin: "5 10 0" }}>{t("title3CT")}</Text>
          <Text style={styles.text}>
            {t("text5CT1")}
            {t("text5CT2")}
          </Text>
          <Text style={{ margin: "5 10 0" }}>{t("title4CT")}</Text>
          <Text style={styles.text}>{t("text7CT")}</Text>
          <Text style={{ margin: "5 10 0", textDecoration: "underline" }}>
            {t("patientConsentCT")}
          </Text>
          <View style={styles.consents}>
            {check1 ? (
              <Image
                src={checkedConsent}
                style={{ height: 15, width: 15, marginLeft: 10 }}
              />
            ) : (
              <Image
                src={uncheckedConsent}
                style={{ height: 15, width: 15, marginLeft: 10 }}
              />
            )}
            <Text style={{ margin: "5 10 0", fontFamily: "RobotoLight" }}>
              {t("check1CT")}
            </Text>
          </View>
          <View style={styles.consents}>
            {check2 ? (
              <Image
                src={checkedConsent}
                style={{ height: 15, width: 15, marginLeft: 10 }}
              />
            ) : (
              <Image
                src={uncheckedConsent}
                style={{ height: 15, width: 15, marginLeft: 10 }}
              />
            )}
            <Text
              style={{
                margin: "5 10 0",
                fontFamily: "RobotoLight",
                width: "100%",
              }}
            >
              {t("check2CT1")}
              {t("check2CT2")}
              {t("check2CT3")}
            </Text>
          </View>
        </View>
        <View style={styles.consents}>
          <View
            style={{
              alignSelf: "flex-start",
              margin: "auto 10",
              fontFamily: "RobotoLight",
            }}
          >
            <Text>{t("Varna")}</Text>
            <Text>{currentDate}</Text>
          </View>
          <View>
            <Image src={signature} style={{ width: 200, marginLeft: "70%" }} />
          </View>
        </View>
        <View>
          <Text style={styles.text}>{t("text6CT")}</Text>
        </View>
      </Page>
    </Document>
  );
  return (
    //visualization of informational consent
    <List>
      <Box
        sx={{
          margin: "auto",
          padding: "0",
        }}
      >
        <Typography variant="body1" gutterBottom component="div" align="right">
          {t("documenttitle1CT")}
        </Typography>
        <Typography variant="body1" gutterBottom component="div" align="right">
          {t("documenttitle2CT1")} <b>{t("documenttitle2CT2")}</b>
        </Typography>
        <Typography variant="body2" gutterBottom component="div" align="right">
          {t("documenttitle3CT")}
        </Typography>
      </Box>
      <Divider style={{ height: "10px" }} />
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ border: "1px solid black" }}>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom component="div" align="left">
                  <b><u>{patientNameRender}</u></b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" gutterBottom component="div" align="right">
                  <b><u>{t("klinik")}</u></b>
                </Typography>
                <Typography variant="body2" gutterBottom component="div" align="right">
                  {t(`clinic1CT${clinic}`)}
                </Typography>
                <Typography variant="body2" gutterBottom component="div" align="right">
                  {t(`${clinic}AddressCT`)}
                </Typography>
                <Typography variant="body2" gutterBottom component="div" align="right">
                  {t(`${clinic}CTPostalN`)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom component="div" align="left" style={{ marginTop: 3 }}>
            <b>{t("title1CT")}</b>
          </Typography>
          <Typography variant="body2" gutterBottom component="div" align="left">
            {t("text1CT")}
          </Typography>
          <Typography variant="body2" gutterBottom component="div" align="left">
            {t("text2CT")}
          </Typography>
          <Typography variant="body2" gutterBottom component="div" align="left">
            {t("text3CT")}
          </Typography>
          <Typography variant="body1" gutterBottom component="div" align="left">
            <b>{t("title2CT")}</b>
          </Typography>
          <Typography variant="body2" gutterBottom component="div" align="left">
            {t("text4CT")}
          </Typography>
          <Typography variant="body1" gutterBottom component="div" align="left">
            <b>{t("title3CT")}</b>
          </Typography>

          <Typography variant="body2" gutterBottom component="div" align="left">
            {t("text5CT1")}{" "}
            <b>
              <i>{t("text5CT2")} </i>
            </b>
          </Typography>
          <Typography variant="body1" gutterBottom component="div" align="left">
            <b>{t("title4CT")}</b>
          </Typography>
          <Typography variant="body2" gutterBottom component="div" align="left">
            {t("text7CT")}
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Typography variant="subtitle1" gutterBottom component="div" align="left">
          <b>
            <u>{t("patientConsentCT")}</u>
          </b>
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Grid container>
                <Grid item xs={1}>
                  <Checkbox
                    checked={check1}
                    disabled={check3}
                    onChange={() => {
                      setCheck1(!check1);
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body1" gutterBottom component="div" align="justify">
                    {t("check1CT")}
                  </Typography>
                </Grid>
              </Grid>
            }
            label=""
            style={{ minWidth: 125 }}
          />
          <FormControlLabel
            control={
              <Grid container>
                <Grid item xs={1}>
                  <Checkbox
                    checked={check2}
                    disabled={check3}
                    onChange={() => {
                      setCheck2(!check2);
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body1" gutterBottom component="div" align="justify">
                    {t("check2CT1")}
                    <b>{t("check2CT2")}</b>
                    {t("check2CT3")}
                  </Typography>
                </Grid>
              </Grid>
            }
            label=""
            style={{ minWidth: 125 }}
          />
        </FormGroup>
      </Box>
      <Grid container spacing={2} justifyContent="space-around" alignItems="flex-end">
        <Grid item xs={5} alignItems="flex-end" direction="column">
          <Typography variant="body2" gutterBottom component="h6" align="justify">
            {t("Varna")},
          </Typography>
          <Typography variant="body2" gutterBottom component="h6" align="justify">
            {currentDate}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="red"
            canvasProps={{
              height: window.innerHeight * 0.25,
              width: window.innerWidth * 0.45,
              className: "sigCanvas",
            }}
            backgroundColor="#f4f5f8"
            onEnd={handleSignatureEnd}
          />
          <Typography
            variant="h6" gutterBottom component="div" align="justify" style={{ width: "90%" }}>
            ({t("informationSignature")})
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" gutterBottom component="div" align="justify">
        {t("text6CT")}
      </Typography>
      <Box
        sx={{
          position: "sticky",
          top: "15px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              style={{
                width: "100%",
                height: "6.25vh",
                borderRadius: "0",
                color: "#fff",
                // eslint-disable-next-line max-len
                background:
                  "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)",
              }}
              onClick={() => {
                sigCanvas.current.clear();
              }}
            >
              {t("Clear")}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              style={{
                width: "100%",
                height: "6.25vh",
                borderRadius: "0",
                color: "#fff",
              }}
              onClick={() => {
                // eslint-disable-next-line max-len
                setSignature(
                  document.querySelector("canvas")?.toDataURL("image/png")
                );
                signatureValidatior();
              }}
            >
              {t("Continue")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("Are you sure you want to submit?")}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              setCount(count + 1);
              //wait to generate on tablets and then post it to Salesforce
              setTimeout(() => {
                uploadFile();
                setOpen(false);
                props.isRendered(false);
              }, 2000);
            }}
          >
            {t("Submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
}
export default XRayConsent;
