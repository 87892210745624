import Cookies from "js-cookie";
import React, { ReactElement } from "react";
import { Redirect, Route } from "react-router-dom";
import { SESSION_ROLE_COOKIE } from "../../Resources/constants";
import { PopupAlert } from "../../Hooks";

const RestrictedRoute = (props: {
    path: string;
    levelOfSecurity: number;
    children: ReactElement
}): ReactElement => {

    const sessionRole = Cookies.getJSON(SESSION_ROLE_COOKIE);

    if (sessionRole && sessionRole >= props.levelOfSecurity) {
        return <Route path={props.path}>{props.children}</Route>;
    } else {
        PopupAlert("You don't have access to that page!", "error");
        return <Redirect to={"/login"} />;
    }
};

export default RestrictedRoute;