import React, { ReactElement } from "react";
import { FormLabel, FormHelperText } from "@mui/material";
import { countryList } from "../../../../../../Resources/constants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";


function CountryListComponent(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any;
}
): ReactElement {
    const { t } = useTranslation();
    return (
        <Grid
            item
            xs={12}
            style={{
                textAlign: "left",
            }}
        >
            <FormLabel>
                <Typography variant='subtitle1'>
                    {props.label}
                </Typography>
            </FormLabel>
            <ReactFlagsSelect
                selected={props.inputValue}
                searchable={true}
                blacklistCountries={false}
                showOptionLabel={true}
                onSelect={(code) => {
                    props.setInputValue(code + "");
                    props.setBlur(props.blurValue + 1);
                }}
            />
            {Boolean(props.inputValue === "") &&
                <FormHelperText>
                    <Typography>
                        {t("Please fill")}
                    </Typography>
                </FormHelperText>}
        </Grid>
    );
}

export default CountryListComponent;