import { useField, useFormikContext } from "formik";
import { ReactElement, useCallback, useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AnswersList } from "../../../../../../Types";
import "react-datepicker/dist/react-datepicker.css";
import { FormikCtx, IFormikContext } from "../../../../../../Contexts/formikContext";
import SliderComponent from "../ComponentsFields/SliderComponent";
import PhoneComponent from "../ComponentsFields/PhoneComponent";
import TextComponents from "../ComponentsFields/TextComponents";
import CalendarComponent from "../ComponentsFields/CalendarComponent";
import NumberComponent from "../ComponentsFields/NumberComponent";
import RadioComponent from "../ComponentsFields/RadioComponent";
import CountryListComponent from "../ComponentsFields/CountryListComponent";

const yesRemarkText = ["да", "Yes", "Ja", "Other"];
const yesConditionalDate = ["", null, "00.00.0000", new Date().toISOString().split("T")[0]
    .split("-").reverse().join(".")];


const SmartInput = (props: {
    type: "text" | "yes_no" | "radio" | "date" | "number";
    questionWidth?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    name: string;
    label: string;
    answers?: AnswersList[];
    showIfConditions?: { questionID: string | undefined, answerValue: string | undefined }[];
    textFieldShowCondition?: { questionID: string, answerValue: string | undefined };
    required: boolean;
}): ReactElement => {
    const customFormikContext = useContext<IFormikContext>(FormikCtx);
    const checkForQuestion = labelIncludes([
        "Скала на страха", "Angstskala", "Шкала для оценки страха", "Fear scale"
    ]);
    const phoneField = labelIncludes([
        "Телефонен номер", "Телефон", "Telefonnummer", "Phone number", "Номер телефона",
        "Numéro de téléphone", "Teléfono", "Telefone", "Număr de telefon", "Recapito telefonico",
        "Telefon / mobiltelefon", "Telefoonnummer/mobiel nummer", "Puh. Numero"
    ]);
    const countryList = labelIncludes(["Държава", "Country", "Land", "Страна"]);
    const emailValidationFiled = labelIncludes(["Имейл", "E-mail", "E-Mail", "Электронная почта"]);
    const checkForMedicines = labelIncludes([
        "Приемате ли медикаменти", "Nehmen Sie Medikamente", "Do you take medicines"
    ]);
    function labelIncludes(keywords: string[]): boolean {
        return keywords.some(keyword => props.label.includes(keyword));
    }
    const RedTextTypography = withStyles({
        root: {
            color: "#FF0000"
        }

    })(Typography);
    const [field, meta] = useField(props.name);
    const [inputValue, setInputValue] = useState<any>(field.value.answerValue);
    const [remarkValue, setRemarkValue] = useState<any>(field.value.remark);

    const [showRemarkTextField, setShowRemarkTextField] = useState(false);
    const { getFieldProps, setFieldValue, setFieldError, values } = useFormikContext();

    const { t } = useTranslation();
    const checkShowIfVisibility = useCallback((): boolean =>
        props.showIfConditions &&
        props
            .showIfConditions
            .map(s =>
                s.questionID && getFieldProps(s.questionID).value.answerValue === s.answerValue
            )
            .find(i => !i) === undefined || false,
        /* true,*/[getFieldProps, props.showIfConditions]);
    const [visibleDueToShowIf, setVisibleDueToShowIf] = useState(checkShowIfVisibility());

    // condition for show remark fields
    //if is selected "No" must delete current value of remark
    useEffect(() => {
        if (showRemarkTextField && (
            props.type === "yes_no" || props.type === "radio")) {
            if (!yesRemarkText.includes(inputValue)) {
                (setRemarkValue(""));
            }
        }
    }, [inputValue]);

    // condition for invalid date input
    //if date is in on today or is missing it sets value in formik as empty string
    useEffect(() => {
        if (props.type === "date") {
            if (yesConditionalDate.includes(inputValue)) {
                setInputValue("");
            }
        }
    });

    useEffect(() =>
        // eslint-disable-next-line max-len
        setVisibleDueToShowIf(checkShowIfVisibility), [checkShowIfVisibility, getFieldProps, props.showIfConditions, values]);

    useEffect(() => {
        if (
            props.textFieldShowCondition &&
            props.textFieldShowCondition.questionID
        ) {
            setShowRemarkTextField(
                inputValue
                === props.textFieldShowCondition?.answerValue
            );
        }
    }, [inputValue, getFieldProps, props.textFieldShowCondition]);

    //field validation regexes
    useEffect(() => {
        const findSuitableRegex = (): [{ test: (input: string) => boolean }, string] => {
            if (props.required && visibleDueToShowIf) {
                return [
                    new RegExp(/^(?!\s*$).+/),
                    "There must be no empty fields."
                ];
            }

            if (emailValidationFiled) {
                return [
                    new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,7}$/g),
                    "The E-mail is incorrect or is empty"
                ];
            }

            return [new RegExp(/(?:)/), ""];
        };

        const validation = findSuitableRegex();

        if (!validation[0].test(inputValue || "")) {
            setFieldError(props.name, validation[1]);
        }
    }, [
        inputValue,
        meta.error,
        props.name,
        props.required,
        props.type,
        setFieldError,
        visibleDueToShowIf,
        emailValidationFiled
    ]);

    const [blurred, setBlurred] = useState<number>(0);

    //TODO
    //using context to update answers in Formik
    useEffect(() => {
        customFormikContext.setUpdateFunctions({
            ...customFormikContext.updateFunctions, [props.name]:
            {
                name: props.name,
                value: { ...field.value, answerValue: inputValue || "", remark: remarkValue }
            }
        });
    }, [inputValue, blurred]);
    if (!visibleDueToShowIf) {
        return <></>;
    } else if (
        !props.required === field.value?.required
    ) {
        const a = field.value;
        a.required = true;
        setFieldValue(props.name, a);
    }

    switch (props.type) {
        case "radio":
        case "yes_no": {
            return <Grid
                container
                item
                sm={props.questionWidth}
                xs={12}
                justifyContent='center'
            >
                <RadioComponent setBlur={setBlurred}
                    blurValue={blurred}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    label={props.label}
                    questionWidth={props.questionWidth}
                    name={props.name}
                    answerLenght={props.answers?.length === 2}
                    mapAnswers={props.answers}
                    emptyField={t("Please fill")} />
                <Grid
                    container
                    item
                    sm={12}
                    justifyContent='center'
                >
                    {showRemarkTextField && <TextComponents
                        setBlur={setBlurred}
                        blurValue={blurred}
                        inputValue={remarkValue}
                        setInputValue={setRemarkValue}
                        label={t("If yes, what")}
                        name={props.name}
                        placeholder={t("click here to write")}
                        type={"text"}
                        emptyField={t("Please fill")}
                    />}
                    {!remarkValue && checkForMedicines && showRemarkTextField &&
                        <Container>
                            <FormHelperText>
                                <RedTextTypography>
                                    {t("Please fill the field")}
                                </RedTextTypography>
                            </FormHelperText>
                        </Container>}
                </Grid>
            </Grid>;
        }
        case "date": {
            return <CalendarComponent
                setBlur={setBlurred}
                blurValue={blurred}
                inputValue={inputValue}
                setInputValue={setInputValue}
                label={props.label}
                questionWidth={props.questionWidth} />;

        }
        case "number": {
            return checkForQuestion ? <SliderComponent setBlur={setBlurred}
                blurValue={blurred}
                inputValue={inputValue}
                setInputValue={setInputValue}
                label={props.label} /> : <NumberComponent setBlur={setBlurred}
                    blurValue={blurred}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    label={props.label}
                    questionWidth={props.questionWidth}
                    name={props.name}
                    placeholder={t("click here to write")} />;
        }
        default: {
            return phoneField ? <PhoneComponent setBlur={setBlurred}
                blurValue={blurred}
                inputValue={inputValue}
                setInputValue={setInputValue}
                label={props.label}
                questionWidth={props.questionWidth}
                name={props.name}
                placeholder={t("phonenumber")} emptyField={t("Please fill")} /> : (emailValidationFiled ? <Grid
                    container
                    item
                    sm={props.questionWidth}
                    xs={12}
                    justifyContent='center'
                ><TextComponents
                        setBlur={setBlurred}
                        blurValue={blurred}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        label={props.label}
                        name={props.name}
                        placeholder={t("click here to write")}
                        type={"email"}
                        emptyField={t("Please fill")}
                    /> </Grid> : (countryList ? <Grid
                        container
                        item
                        sm={props.questionWidth}
                        xs={12}
                        justifyContent='center'
                    ><CountryListComponent
                            setBlur={setBlurred}
                            blurValue={blurred}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            label={props.label}
                        />
                    </Grid> : <Grid
                        container
                        item
                        sm={props.questionWidth}
                        xs={12}
                        justifyContent='center'
                    ><TextComponents
                            setBlur={setBlurred}
                            blurValue={blurred}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            label={props.label}
                            name={props.name}
                            placeholder={t("click here to write")}
                            type={"text"}
                            emptyField={t("Please fill")}
                        />
                    </Grid>));
        }
    }
};
export default SmartInput;