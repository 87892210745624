import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import { PopupAlert } from "../../Hooks";
import { getLoggedInPersonInfo, login } from "../../Providers/backend";
import {
    CORE_ACCESS_TOKEN_COOKIE,
    PATIENT_ID_COOKIE,
    SESSION_ROLE_COOKIE,
} from "../../Resources/constants";
import { UserRoles } from "../../Resources/enums";

export const useOnSubmit = (): ((values: { email: string; password: string }) => void) => {
    const history = useHistory();

    Cookies.remove(SESSION_ROLE_COOKIE);
    Cookies.remove(CORE_ACCESS_TOKEN_COOKIE);

    const redirect = () => {
        switch (Cookies.getJSON(SESSION_ROLE_COOKIE)) {
            case UserRoles.ADMIN: {
                history.push("/adminPanel");
                break;
            }
            case UserRoles.USER: {
                history.push("/login");
                break;
            }
            default: {
                history.push("/filledForm");
                PopupAlert("It seems you don't have access to the site", "warning");
                break;
            }
        }
    };

    return (values: { email: string; password: string }) =>
        trackPromise(
            login(values.email, values.password)
                .then((val) => {
                    if (!val.data) {
                        throw new Error("Empty login data.");
                    }
                    Cookies.set(CORE_ACCESS_TOKEN_COOKIE, val.data.token);
                })
                .then(() => getLoggedInPersonInfo(Cookies.getJSON(CORE_ACCESS_TOKEN_COOKIE)))
                .then((val) => {
                    Cookies.set(
                        SESSION_ROLE_COOKIE,
                        //TODO: Replace with JWT form backend
                        UserRoles.ADMIN.toString()
                    );
                    Cookies.set(
                        PATIENT_ID_COOKIE,
                        val.data.salesforce_id || val.data.webflow_id?.toString() || "123"
                    );
                })
                .then(() => redirect())
                .catch(() => PopupAlert("Login error", "error"))
        );
};
