import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import default_en from "./Languages/en.json";
import default_bg from "./Languages/bg.json";
import default_de from "./Languages/de.json";
import default_ru from "./Languages/ru.json";
import default_dk from "./Languages/dk.json";
import default_es from "./Languages/es.json";
import default_fi from "./Languages/fi.json";
import default_fr from "./Languages/fr.json";
import default_it from "./Languages/it.json";
import default_nl from "./Languages/nl.json";
import default_no from "./Languages/no.json";
import default_pt from "./Languages/pt.json";
import default_ro from "./Languages/ro.json";
import default_sw from "./Languages/sw.json";
import Cookies from "js-cookie";

i18n.use(initReactI18next)
    .init({
        //setting default language by cookies selected from patient card ("Language") or from Salesforce ("Lang")
        lng: (Cookies.get("Language")?.toLowerCase()) ||
            (Cookies.get("Lang") === "English" ? "en" :
                (Cookies.get("Lang") === "Bulgarian" ? "bg" : "de")),
        resources: {
            en: {
                translation: default_en
            },
            de: {
                translation: default_de
            },
            ru: {
                translation: default_ru
            },
            bg: {
                translation: default_bg
            },
            dk: {
                translation: default_dk
            },
            es: {
                translation: default_es
            },
            fi: {
                translation: default_fi
            },
            fr: {
                translation: default_fr
            },
            it: {
                translation: default_it
            },
            nl: {
                translation: default_nl
            },
            no: {
                translation: default_no
            },
            pt: {
                translation: default_pt
            },
            ro: {
                translation: default_ro
            },
            sw: {
                translation: default_sw
            }
        },
        fallbackLng: "en",
        react: {
            useSuspense: false,
            wait: true
        }
    });

export default i18n;