import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trackPromise } from "react-promise-tracker";
import "../../../../i18n/i18next";
import { Languages } from "../../../../Resources/enums";

const DEFAULT_LANGUAGE = Cookies.get("Language") === "EN" ? 
    Languages.EN : (Cookies.get("Language") === "BG" ? Languages.BG : Languages.DE);

export const useSelectLanguage = (
    languages: Languages[]
): [Languages, (event: { target: { value: unknown; }; }) => void] => {

    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(() =>
        languages.find(val => val === (navigator.language).slice(0, 2)) || DEFAULT_LANGUAGE);
    
    const changeLanguage = (event: { target: { value: unknown; }; }) => {
        const new_language = languages.find(val => val === event.target.value) || DEFAULT_LANGUAGE;

        setSelectedLanguage(new_language);
        trackPromise(i18n.changeLanguage(new_language.toLowerCase()));
    };

    return [selectedLanguage, changeLanguage];
};