import { useFormikContext } from "formik";

export const useUserData = (): () => string => {

    const a = useFormikContext().values as Record<string, any>;

    //TODO: This may break of your personal info is not the first page;
    return (): string =>
        Object.values(a)[0].answerValue + "\t" +
        Object.values(a)[1].answerValue + "\t" +
        new Date().toLocaleDateString();
};