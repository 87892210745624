import { Button, Fade, FormControl, FormLabel, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import React, { ReactElement } from "react";
import FormattedText from "../../Components/FormattedText";
import { TRANSITION_TIMEOUT } from "../../Resources/constants";
import { useOnSubmit } from "./Hooks";

const AdminPanel = (): ReactElement => {

    const onSubmit = useOnSubmit();

    const formik = useFormik({
        initialValues: { id: "" },
        onSubmit: a => onSubmit(a),
    });
    return <form
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "60vh",
        }}
        onSubmit={formik.handleSubmit}
    >
        <Fade in={true} timeout={TRANSITION_TIMEOUT}>
            <FormControl>
                <FormLabel style={{ marginBottom: "32%" }}>
                    <FormattedText text='Enter user ID' variant='h1' />
                </FormLabel>
                <FormControl
                    style={{
                        textAlign: "left",
                        padding: "3%",
                    }}>
                    <FormLabel>
                        <FormattedText text='ID' variant='subtitle1' />
                    </FormLabel>
                    <TextField
                        style={{
                            marginBottom: "10%"
                        }}
                        fullWidth
                        id='id'
                        name='id'
                        type='text'
                        placeholder='click here to write'
                        onChange={formik.handleChange}
                        value={formik.values.id}
                    />
                    <Button type='submit'>
                        <FormattedText text='submit' />
                    </Button>
                </FormControl>
            </FormControl>
        </Fade >
    </form>;
};

export default AdminPanel;