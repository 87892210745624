import React, { ReactElement, useState, useRef } from "react";
import { FormLabel } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Input } from "@material-ui/core";

function NumberComponent(props: {
    setBlur: (pageNum: React.SetStateAction<number>) => void;
    blurValue: number;
    inputValue: any;
    setInputValue: (pageNum: React.SetStateAction<any>) => void;
    label: any;
    questionWidth: any;
    name: any;
    placeholder: any;
}
): ReactElement {
    return (
        <Grid
            container
            item
            sm={props.questionWidth}
            xs={12}
            justifyContent='center'
        >
            <Grid
                item
                xs={12}
                style={{
                    textAlign: "left",
                }}
            >
                <FormLabel>
                    <Typography variant='subtitle1'>
                        {props.label}
                    </Typography>
                </FormLabel>
                <Input
                    onBlur={() => props.setBlur(props.blurValue + 1)}
                    fullWidth={true}
                    id={props.name}
                    name={props.name}
                    placeholder={props.placeholder}
                    onChange={(val: { target: { value: any; }; }) => {
                        props.setInputValue(val.target.value);
                    }}
                    value={props.inputValue || ""}
                    error={Boolean(props.inputValue === "")}
                    type={"number"}
                    inputProps={{
                        inputMode: "numeric", pattern: "[0-9]*",
                        min: 1, max: 10
                    }} />
            </Grid>
        </Grid>
    );
}

export default NumberComponent;