import React, { ReactElement } from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormattedText from "../../../../Components/FormattedText";
import { Question, PageTitle } from "../../../../Types";

type CheckDataProps = {
    pageTitles: PageTitle[],
    questions: Question[];
};

const CheckData = ({ pageTitles, questions }: CheckDataProps): ReactElement => {
    const { getFieldProps } = useFormikContext();

    return (
        <List>
            {pageTitles.map((page: PageTitle) => (
                <ListItem key={page.title}>
                    <ListItemText
                        primaryTypographyProps={{ variant: "h5" }}
                        primary={`${page.title}:`}
                        secondaryTypographyProps={{ component: "div", display: "block" }}
                        secondary={
                            <List>
                                {questions.filter(question => question.page === page.page).map((item: Question) => (
                                    <ListItem key={item.questionText}>
                                        <ListItemText
                                            primary={
                                                <div>
                                                    <Typography style={{ color: "#565657" }}>
                                                        {item.questionText.endsWith(":") ? item.questionText + "\t" : item.questionText + ":\t"}
                                                    </Typography>
                                                    <FormattedText
                                                        style={{ fontWeight: 900, color: "#565657" }}
                                                        text={getFieldProps(item.questionID).value.answerValue + "\t"}
                                                    />
                                                    <FormattedText
                                                        style={{ fontWeight: 900, color: "#565657" }}
                                                        text={getFieldProps(item.questionID).value.remark}
                                                    />
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default CheckData;
