import { Button, Fade, FormControl, FormLabel, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import React, { ReactElement } from "react";
import { TRANSITION_TIMEOUT } from "../../Resources/constants";
import FormattedText from "../../Components/FormattedText";
import { useOnSubmit } from "./Hooks";

const LoginForm = (): ReactElement => {

    const onSubmit = useOnSubmit();

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        onSubmit: a => onSubmit(a),
    });

    return <form onSubmit={formik.handleSubmit}>
        <Fade in={true} timeout={TRANSITION_TIMEOUT}>
            <FormControl
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh"
                }}>
                <FormLabel>
                    <FormattedText text='login' variant='h1' />
                </FormLabel>
                <FormControl
                    style={{
                        textAlign: "left",
                        marginTop: "10%",
                    }}
                >
                    <FormLabel>
                        <FormattedText text='email' />
                    </FormLabel>
                    <TextField
                        style={{
                            marginBottom: "8%"
                        }}
                        fullWidth
                        id='email'
                        name='email'
                        type='email'
                        placeholder='click here to write'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    <FormLabel>
                        <FormattedText text='password' variant='body1' />
                    </FormLabel>
                    <TextField
                        style={{
                            marginBottom: "10%"
                        }}
                        fullWidth
                        id='password'
                        name='password'
                        type='password'
                        placeholder='click here to write'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                    <Button type='submit'>
                        <FormattedText text='submit' />
                    </Button>
                </FormControl>
            </FormControl>
        </Fade >
    </form>;
};

export default LoginForm;