import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import FormattedText from "../../Components/FormattedText";
import confetti from "canvas-confetti";
import { ReactComponent as ConfettiSVG } from "./confetti.svg";


const startConfetti = () => {
    const duration = 15 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    const interval: NodeJS.Timer = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        const particleCount = 150 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(Object.assign(
            {},
            defaults,
            {
                particleCount,
                origin: {
                    x: randomInRange(0.1, 0.3),
                    y: Math.random() - 0.2
                }
            }
        ));
        confetti(Object.assign(
            {},
            defaults,
            {
                particleCount,
                origin: {
                    x: randomInRange(0.7, 0.9),
                    y: Math.random() - 0.2
                },
            }
        ));
    }, 500);
};

const CongratulationsPage = (): ReactElement => {

    const history = useHistory();
    //eslint-disable-next-line
    { localStorage.getItem("local") === "true" && (Cookies.get("facilityName") !== "Dentaprime London" || Cookies.get("facilityName") !== "Dentaprime%20London") ? startConfetti() : null };

    return <Box
        onClick={() => {
            // PopupAlert("Data posted successfully", "success");
            //Removes all cookies; //TODO: Test
            Object.keys(Cookies.get()).forEach(cookieName => Cookies.remove(cookieName));
            if (localStorage.getItem("local") === "true") {
                history.push("/login");
            } else {
                history.push("/filledForm");
            }
            history.goForward();
        }}
        style={{
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
        }}>
        {/* eslint-disable-next-line */}
        {localStorage.getItem("local") === "true" && (Cookies.get("facilityName") !== "Dentaprime London" || Cookies.get("facilityName") !== "Dentaprime%20London") ?
            <div>
                <ConfettiSVG />
            </div> : null}
        <div style={{
            marginTop: "5%",
        }}>
            <FormattedText text="CongratsTitle" variant="h1" />
            {/* eslint-disable-next-line */}
            {localStorage.getItem("local") === "true" ? <FormattedText text="CongratsText" variant="subtitle1" />
                : <FormattedText text="InternalCongrats" variant="subtitle1" />}

        </div>
    </Box>;
};

export default CongratulationsPage;