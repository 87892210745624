import { Chip, Grid, Divider } from "@material-ui/core";
import React, { ReactElement } from "react";
import FormattedText from "../../../../Components/FormattedText";
import { Agents, CityClinics, FacilityNames, Languages } from "../../../../Resources/enums";

const FacilitySelect = (props: {
    facilityName?: FacilityNames | CityClinics | Agents | Languages;
    facilities: FacilityNames[] | CityClinics[] | Agents[] | Languages[];
    handleOnChange: (...args: any[]) => any;
}): ReactElement => <Grid container spacing={2} >
        {props.facilities.map((val: string, num: number) =>
            <Grid
                item
                key={val}
                xs={12}
                onClick={() => props.handleOnChange(val)}
                style={{
                    textAlign: "center",
                    padding: "0%"
                }}>
                <Chip
                    clickable
                    label={<FormattedText
                        style={{
                            color: props.facilityName === val ? "white" : "black"
                        }}
                        variant='subtitle1'
                        text={val} />}
                    onClick={() => props.handleOnChange(val)}
                    style={{
                        background: props.facilityName !== val ? "#fff" : "",
                        width: "47.5vw",
                        height: "5vh"
                    }}
                />
                {
                    num < props.facilities.length - 1 &&
                    <Divider
                        component='div'
                        variant='middle'
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "37.5vw",
                            height: "0.2vh",
                        }}
                    />
                }
            </Grid>
        )}
    </Grid>;

export default FacilitySelect;