import { createTheme } from "@material-ui/core/styles";
import {
    DENTAPRIME_PRIMARY_COLOR,
    DENTAPRIME_SECONDARY_COLOR,
    MARGIN,
    PADDING
} from "./Resources/constants";
import "fontsource-roboto";


const DentaprimeTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 610,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    overrides: {
        MuiButtonGroup: {
            root: {
                margin: MARGIN,
                padding: PADDING,
            },
        },
        MuiButton: {
            root: {
                borderRadius: "30px",
                background:
                    "linear-gradient(0deg, rgb(141,45,70) 13%," +
                    " rgb(177,31,71) 59%, rgb(212,17,71) 100%)"
            }
        },
        MuiChip: {
            root: {
                borderRadius: "30px",
                background:
                    "linear-gradient(0deg, rgb(141,45,70) 13%," +
                    "rgb(177,31,71) 59%, rgb(212,17,71) 100%)"
            }
        },
        MuiCheckbox: {
            root: {
                margin: MARGIN,
                padding: PADDING,
            },
        },
        MuiSlider: {
            root: {
                margin: MARGIN,
                padding: PADDING,
            },
        },
        MuiToolbar: {
            root: {
                margin: MARGIN,
                padding: "3%",
            },
        },
        MuiGrid: {
            root: {
                margin: MARGIN,
                padding: "1%",
            },
            container: {
                width: "auto",
            }
        },
        MuiListItem: {
            root: {
                paddingTop: "0%",
                paddingBottom: "0%",
            },
        },
        MuiListItemText: {
            root: {
                margin: "0%"
            }
        },
        MuiPopover: {
            paper: {
                borderRadius: "30px"
            }
        },
        MuiDialog: {
            paper: {
                alignItems: "center",
                borderRadius: "30px",
            }
        }
    },
    palette: {
        primary: {
            main: DENTAPRIME_PRIMARY_COLOR
        },
        secondary: {
            main: DENTAPRIME_SECONDARY_COLOR
        },
    },
    props: {
        MuiCheckbox: {
            color: "primary",
        },
        MuiSelect: {
            style: {
                minWidth: 250,
            }
        },
        MuiButton: {
            variant: "contained",
            color: "primary",
            size: "large",
        },
        MuiButtonGroup: {
            color: "primary",
        },
        MuiFormControl: {
            style: {
                justifyContent: "center",
                alignItems: "center",
            },
        },
        MuiAppBar: {
            color: "secondary",
            elevation: 0,
        },
        MuiTypography: {
            variantMapping: {
                h1: "h1",
                h2: "h1",
                h3: "h1",
                h4: "h1",
                h5: "h1",
                h6: "h1",
                subtitle1: "subtitle1",
                subtitle2: "subtitle1",
                body1: "body1",
                body2: "body1",
            },
        },
    },
    typography: {
        fontFamily: "Roboto",
        h1: {
            fontSize: 30,
            color: "black",
        },
        subtitle1: {
            fontSize: 25,
            color: "black",
            lineHeight: 1
        },
        body1: {
            fontSize: 20,
            fontWeight: 500
        },
    }
});

export default DentaprimeTheme;