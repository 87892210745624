import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Question } from "../../../../Types";
import SmartInput from "./Components/SmartInput";

const QuestionsGrid = (props: {
    questions: Question[]
}): ReactElement => {
    const questionWidth = props.questions.length > 5 && window.innerWidth > 600 ? 6 : 12;


    const showRemarkCondition = (q: Question) => {
        const res = q.answer.answersList?.find(i => i.remarkRequired) ? {
            questionID: q.questionID,
            answerValue: q.answer.answersList?.find(i => i.remarkRequired)?.text
        } : undefined;
        return res;
    };
    const getShowIfConditions =
        (q: Question): { questionID: string | undefined, answerValue: string | undefined }[]
            | undefined => {
            const arr = [
                {
                    questionID: q.showIf?.question_uid,
                    answerValue: q.showIf?.answerValue
                }
            ];

            q.showIfList?.forEach(s => arr.push(
                {
                    questionID: s.question_uid,
                    answerValue: s.answerValue
                }
            ));

            return arr;
        };
    return <Grid
        container
        direction="row">
        {
            props.questions.map(i =>
                <SmartInput
                    questionWidth={12}
                    key={i.questionText}
                    name={i.questionID}
                    label={i.questionText}
                    type={i.answer.answerType}
                    answers={i.answer.answersList}
                    showIfConditions={getShowIfConditions(i)}
                    textFieldShowCondition={showRemarkCondition(i)}
                    required={i.required}
                />
            )
        }
    </Grid >;
};

export default QuestionsGrid;