import React, { FunctionComponent, ReactNode, useState } from "react";
import { FormikCtx } from "../formikContext";

//props we will pass in Context API
interface FormikContextProviderProps {
    step: number,
    setStep: React.Dispatch<React.SetStateAction<number>>
    stepUpdateAttempt: number,
    setStepUpdateAttempt: React.Dispatch<React.SetStateAction<number>>
    children: ReactNode
}

const CustomFormikContextProvider: FunctionComponent<FormikContextProviderProps> =
    ({ step, setStep, stepUpdateAttempt, setStepUpdateAttempt, children }) => {
        const [updateFunctions, setUpdateFunctions] =
            useState<Record<string, { name: any, value: any }>>({});

        //passed props in Formik which it works with in Context API
        return <FormikCtx.Provider value={{
            step,
            setStep,
            stepUpdateAttempt,
            setStepUpdateAttempt,
            updateFunctions,
            setUpdateFunctions
        }}>
            {/* This children prop stands for Formik Component */}
            {children}
        </FormikCtx.Provider>;
    };

export default CustomFormikContextProvider;