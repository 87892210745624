import React, { ReactElement } from "react";
import "./style.css";

const ProgressBar = (props: { currentStep: number, steps: number }): ReactElement => {

    const asd = new Array(props.steps).fill(0);

    return <ul
        className={"progressbar"}
    >
        {asd.map((a, i) => <li key={i} className={props.currentStep > i - 1 ? "active" : ""} />)}
    </ul>;
};

export default ProgressBar;