import axios, { AxiosPromise } from "axios";
import Cookies from "js-cookie";
import {
  BACKEND_URL,
  CORE_ACCESS_TOKEN_COOKIE,
  PATIENT_ID_COOKIE,
  FACILITY_NAME_COOKIE,
  CITY_LOCATION_NAME,
  AGENT,
  BACKEND_VERSION,
} from "../Resources/constants";
import { Agents, FacilityNames } from "../Resources/enums";
import { PageTitle, Question, UserData } from "../Types";

export const getInstance = () => {
  const instance = axios.create({
    baseURL: BACKEND_URL,
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: Cookies.getJSON(CORE_ACCESS_TOKEN_COOKIE),
    },
  });
  instance.interceptors.response.use((res) => {
    if (res.headers["x-api-version"] !== BACKEND_VERSION) {
      window.location.reload();
    }
    return res;
  });

  return instance;
};

export const checkID = (id: string): AxiosPromise<unknown> =>
  getInstance().get("/checkID", {
    params: {
      patientID: id.trim(),
    },
  });

export const getLanguage = (id: string): AxiosPromise<unknown> =>
  getInstance().get("/languages", {
    params: {
      patientID: id.trim(),
    },
  });
  export const getIDFromWF = (id: string): AxiosPromise<unknown> =>
  getInstance().get("/getWFID", {
    params: {
      wfID: id.trim(),
    },
  });
  export const getIDFromToken = (token: string): AxiosPromise<unknown> =>
  getInstance().get("/getFromToken", {
    params: {
      token,
    },
  });

export const login = (email: string, password: string) =>
  getInstance()
    .post("/login", {
      email: email.trim(),
      password: password.trim(),
    })
    .then((res) => {
      return res;
    });

export const getLoggedInPersonInfo = (
  accessToken: string
): AxiosPromise<UserData> =>
  getInstance().get("/me", {
    headers: {
      Authorization: accessToken,
    },
  });

const facilityNameToID = (facilityName: FacilityNames): number => {
  switch (facilityName) {
    case FacilityNames.CLINIC:
      return 241;
    case FacilityNames.CITY:
      return 12;
    case FacilityNames.F3T:
      return 223;
    default:
      return 223;
  }
};

export const getQuestionsAndAnswers = (): AxiosPromise<{
  SFID: string;
  formID: string;
  questions: Question[];
  pageTitles: PageTitle[];
  hasAppointmentInNext24h: boolean;
}> =>
  getInstance().get("/getFormQuestionsAndAnswers", {
    params: {
      patientID: Cookies.get(PATIENT_ID_COOKIE) + "",
      lang:
        Cookies.get("Language") + "" === undefined
          ? "de"
          : Cookies.get("Language") + "",
      formTemplateID:
        facilityNameToID(Cookies.getJSON(FACILITY_NAME_COOKIE)) || 11,
    },
  });

export const updateAnswers = (values: {
  [s: string]: unknown;
}): AxiosPromise<unknown> =>
  getInstance().post(
    "/formUpload",
    {
      answers: JSON.stringify(values),
      sid:Cookies.get("sid")+""
    },
    {
      params: {
        agent: Cookies.get(AGENT) + "",
        patientID: Cookies.get(PATIENT_ID_COOKIE) + "",
        lang: Cookies.get("Language") + "" || "de",
        formTemplateID:
          facilityNameToID(Cookies.getJSON(FACILITY_NAME_COOKIE)) || 11,
        cityClinic: Cookies.get(CITY_LOCATION_NAME),
        clearEmailAndPhone: !(
          Cookies.get(CITY_LOCATION_NAME) || Cookies.get(AGENT) === Agents.Local
        ),
      },
    }
  );

export const closeForm = (
  values: { [s: string]: unknown },
  canvas: string
): AxiosPromise<unknown> =>
  getInstance().post(
    "/formUpload",
    {
      answers: JSON.stringify(values),
      sid:Cookies.get("sid"),
      // Signature takes the canvas value or takes white base64 string
      signature:
        canvas ||
        //eslint-disable-next-line max-len
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=",
    },
    {
      params: {
        agent: Cookies.get(AGENT) + "",
        patientID: Cookies.get(PATIENT_ID_COOKIE) + "",
        lang: Cookies.get("Language") + "" || "de",
        formTemplateID:
          facilityNameToID(Cookies.getJSON(FACILITY_NAME_COOKIE)) || 11,
        cityClinic: Cookies.get(CITY_LOCATION_NAME),
        clearEmailAndPhone: !(
          Cookies.get(CITY_LOCATION_NAME) || Cookies.get(AGENT) === Agents.Local
        ),
      },
    }
  );

export const sendDeclarationToNC = (
  file: string,
  fileName: string,
  patientID: string
): AxiosPromise<unknown> =>
  getInstance().post("/declarationSend", {
    file,
    fileName,
    patientID,
  });
  export const sendFileToServiceOS = (
  file: string,
  fileName: string,
  naming: string,
  id: string
): AxiosPromise<unknown> =>
  getInstance().post("/fileAttach", {
    fileName,
    file,
    naming,
    id,
  });
export const sendFileToSF = (
  file: string,
  fileName: string,
  naming: string,
  patientID: string
): AxiosPromise<unknown> =>
  getInstance().post("/consent", {
    file,
    fileName,
    naming,
    patientID,
  });
export const sendProfilePictureToWF = (
  file: string,
  fileName: string,
  patientID: string
): AxiosPromise<unknown> =>
  getInstance().post("/sendPicture", {
    file,
    fileName,
    patientID,
  });
  export const sendQuestionnaire = (
  file: string,
  patientID: string,
  facility: string,
  birthday:string,
  email:string,
  language:any,
  sid:string,
): AxiosPromise<unknown> =>
  getInstance().post("/questionnaireSend", {
    file,
    patientID,
    facility,
    birthday,
    email,
    language,
    sid
  });
