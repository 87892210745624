import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const FormattedText = (props: {
    variant?: "h1" | "subtitle1" | "body1";
    style?: React.CSSProperties;
    text: string;
}): ReactElement => {

    const { t } = useTranslation();

    return <Typography
        variant={props.variant}
        style={props.style}
    >
        {t(props.text)}
    </Typography >;
};

export default FormattedText;