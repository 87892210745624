import { AppBar, Toolbar } from "@material-ui/core";
import React, { ReactElement, useContext } from "react";
import LanguageSelect from "./Components/LanguageSelect";
import { ReactComponent as Logo } from "./Assets/logo.svg";
import { DisableLanguageContext } from "../../Contexts";

const TopBar = (): ReactElement => {

    const [DisableLanguage,] = useContext(DisableLanguageContext);

    return <AppBar position='static'>
        <Toolbar>
            <Logo style={{ height: "7vh" }} />
            <div style={{ flexGrow: 1 }} />
            {/* <LanguageSelect disabled={DisableLanguage} /> */}
        </Toolbar>
    </AppBar>;
};

export default TopBar;